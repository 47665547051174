import React from "react";
import { useLocation } from "react-router-dom";

import { SidebarItemsType } from "../../types/sidebar";
import reduceChildRoutes from "./reduceChildRoutes";
import { useAppSelector } from "../../redux/hooks";
import { getUserSelector } from "../../redux/selectors/getAuthData";

type SidebarNavListProps = {
  depth: number;
  pages: SidebarItemsType[];
  onClose?: () => void;
};

const SidebarNavList: React.FC<SidebarNavListProps> = (props) => {
  const { pages, depth, onClose } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const user = useAppSelector(getUserSelector);

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth, onClose, currentRole: user.role.name }),
    [] as JSX.Element[]
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
