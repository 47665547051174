import { combineReducers } from '@reduxjs/toolkit';
import authReducer, { AuthState } from './auth';
import ordersReducer, { OrdersState } from './orders';
import changeLogsReducer, { ChangeLogState } from './changeLogs';
import consultantsReducer, { ConsultantsState } from './consultants';
import availabilitiesReducer, { AvailabilitiesState } from './availabilities';
import commonReducer, { CommonState } from './common';
import reviewsReducer, { ReviewsState } from './reviews';
import customersReducer, { CustomersState } from './customers';
import tagsReducer, { TagsState } from './tags';
import companiesReducer, { CompaniesState } from './companies';
import agenciesReducer, { AgenciesState } from './agencies';
import snackNotificationsReducer, { SnackNotificationsState } from './snackNotifications';
import feedbacksReducer, { FeedbacksState } from './feedbacks';
import productsReducer, { ProductsState } from './products';
import purchaseOrdersReducer, { PurchaseOrdersState } from './purchaseOrders';
import enterpriseInvoicingReducer, { EnterpriseInvoicingState } from './enterpriseInvoicing';
import payoutsReducer, { PayoutsState } from './payouts';
import leadReducer, { LeadState } from './leads';
import leadManagerReducer, { LeadManagersState } from './leadManagers';
import serviceCatalogEntriesReducer, { ServiceCatalogState } from "./serviceCatalogEntries";
import leadSourceReducer, { LeadSourcesState } from "./leadSources";
import businessRulesReducer, { BusinessRulesState } from './businessRules';
import usersReducer, { UsersState } from './users';
import influencerReducer, { InfluencerState } from './influencers';

const rootReducer = combineReducers({
  auth: authReducer,
  orders: ordersReducer,
  changeLogs: changeLogsReducer,
  consultants: consultantsReducer,
  availabilities: availabilitiesReducer,
  common: commonReducer,
  reviews: reviewsReducer,
  customers: customersReducer,
  tags: tagsReducer,
  companies: companiesReducer,
  agencies: agenciesReducer,
  snackNotifications: snackNotificationsReducer,
  feedbacks: feedbacksReducer,
  products: productsReducer,
  purchaseOrders: purchaseOrdersReducer,
  enterpriseInvoicing: enterpriseInvoicingReducer,
  payouts: payoutsReducer,
  leads: leadReducer,
  leadManagers: leadManagerReducer,
  serviceCatalogEntries: serviceCatalogEntriesReducer,
  businessRules: businessRulesReducer,
  leadSources: leadSourceReducer,
  users: usersReducer,
  influencers: influencerReducer,
});

export default rootReducer;

export type TRootState = {
  auth: AuthState;
  orders: OrdersState;
  changeLogs: ChangeLogState;
  consultants: ConsultantsState;
  availabilities: AvailabilitiesState;
  common: CommonState;
  reviews: ReviewsState;
  customers: CustomersState;
  tags: TagsState;
  companies: CompaniesState;
  agencies: AgenciesState;
  snackNotifications: SnackNotificationsState;
  feedbacks: FeedbacksState;
  products: ProductsState;
  purchaseOrders: PurchaseOrdersState;
  enterpriseInvoicing: EnterpriseInvoicingState;
  payouts: PayoutsState;
  leads: LeadState;
  leadManagers: LeadManagersState;
  serviceCatalogEntries: ServiceCatalogState;
  leadSources: LeadSourcesState;
  businessRules: BusinessRulesState;
  users: UsersState;
  influencers: InfluencerState;
};

declare module 'react-redux' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultRootState extends TRootState {}
}
