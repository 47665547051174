import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { ACTION_INFLUENCERS_LIST, ACTION_INVITE_INFLUENCER, REDUCER_KEY_INFLUENCERS } from "../constants";
import ApiClient from "../../api/ApiClient";
import { InfluencerInvite } from "../../types/influencer";

export interface InfluencerState {
  isFetching: boolean;
  influencers: any[];
  count: number;
  page: number;
}

const initialState: InfluencerState = {
  isFetching: false,
  influencers: [],
  count: 0,
  page: 0,
}

const fetchInfluencerList = createAsyncThunk<AxiosResponse<any>>(
  `${REDUCER_KEY_INFLUENCERS}/${ACTION_INFLUENCERS_LIST}`,
  async () => {
    return ApiClient.fetchInfluencerList();
  },
);

const sendInfluencerInvite = createAsyncThunk<AxiosResponse<any>, InfluencerInvite>(
  `${REDUCER_KEY_INFLUENCERS}/${ACTION_INVITE_INFLUENCER}`,
  async ( userInvite ) => {
    console.log("User data: ", userInvite);
    return await ApiClient.inviteInfluencer(userInvite);
  },
)

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_INFLUENCERS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencerList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchInfluencerList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchInfluencerList.fulfilled, (state, { payload }) => {
        state.influencers = payload.data.consultants;
        state.count = payload.data.count;
        state.isFetching = false;
      })
      .addCase(sendInfluencerInvite.fulfilled, (state, { payload }) => {
        state.influencers.push(payload.data);
        state.count++;
      })
  },
});

export {
  fetchInfluencerList,
  sendInfluencerInvite
};

export default reducer;