import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REDUCER_KEY_SNACK_NOTIFICATIONS } from '../constants';
import { EHorizontalPosition, ESeverity, EVerticalPosition, SnackbarProps } from '../../types/common';

export interface SnackNotificationsState {
  isOpen: boolean;
  severity: ESeverity;
  vertical: EVerticalPosition;
  horizontal: EHorizontalPosition;
  message?: string;
}

const initialState: SnackNotificationsState = {
  isOpen: false,
  severity: ESeverity.INFO,
  vertical: EVerticalPosition.TOP,
  horizontal: EHorizontalPosition.CENTER,
  message: undefined,
}

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_SNACK_NOTIFICATIONS,
  initialState,
  reducers: {
    resetSnackNotifications: (state) => {
      state.isOpen = false;
      state.message = undefined;
      state.severity = ESeverity.INFO;
      state.vertical = EVerticalPosition.TOP;
      state.horizontal = EHorizontalPosition.CENTER;
    },
    setSnackNotification: (state, { payload }: PayloadAction<SnackbarProps>) => {
      state.isOpen = payload.isOpen;
      state.message = payload.message;
      state.severity = payload.severity || ESeverity.INFO;
      state.vertical = payload.vertical || EVerticalPosition.TOP;
      state.horizontal = payload.horizontal || EHorizontalPosition.CENTER;
    },
  },
});

const {
  resetSnackNotifications,
  setSnackNotification,
} = actions;

export {
  resetSnackNotifications,
  setSnackNotification,
};

export default reducer;
