import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_COMPANIES,
  ACTION_NAME_ADD_COMPANY_ITEM,
  ACTION_NAME_EDIT_COMPANY_ITEM,
  ACTION_NAME_FETCH_COMPANIES_LIST,
  ACTION_NAME_ADD_COMPANY_ITEM_LOGO,
  ACTION_NAME_EDIT_COMPANY_ITEM_ENTERPRISE_STATUS,
  ACTION_NAME_CHANGE_COMPANY_TEAM_ACTIVE_STATUS,
  ACTION_NAME_CHANGE_COMPANY_EMAIL,
  ACTION_NAME_FETCH_COMPANY_ITEM,
  ACTION_NAME_FETCH_TEAMS_LIST,
} from '../constants'
import ApiClient from 'api/ApiClient';
import {
  CompanyCreationProps,
  CompanyEnterpriseEditingProps,
  CompanyProps,
  Team,
} from 'types/companies';
import dataToImgFile from 'utils/dataToImgFile';

const ROWS_PER_PAGE = 25;

export interface CompaniesState {
  isFetching: boolean;
  companies: CompanyProps[];
  count: number;
  page: number;
  rowsPerPage: number;
  isSaving: boolean;
  selectedCompanyItem?: CompanyProps;
  teams?: Team[];
}

const initialState: CompaniesState = {
  isFetching: false,
  companies: [],
  count: 0,
  page: 0,
  rowsPerPage: ROWS_PER_PAGE,
  isSaving: false,
  selectedCompanyItem: undefined,
}

const fetchCompaniesList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number,
  limit?: number,
  search?: string,
  isEnterprise?: boolean,
  property?: string,
  type?: "desc" | "asc",
}>(
  `${REDUCER_KEY_COMPANIES}/${ACTION_NAME_FETCH_COMPANIES_LIST}`,
  async ({
    offset = 0,
    limit = ROWS_PER_PAGE,
    search,
    isEnterprise,
    property,
    type,
  }) => {
    const response = await ApiClient.fetchCompaniesList({ offset, limit, search, isEnterprise, property, type });
    return response;
  },
);

const fetchCompanyItem = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_COMPANIES}/${ACTION_NAME_FETCH_COMPANY_ITEM}`,
  async ({ id }) => {
    const response = await ApiClient.fetchCompanyItem(id);
    return response;
  },
);

const addCompanyItem = createAsyncThunk<AxiosResponse<any>, { company: CompanyCreationProps }>(
  `${REDUCER_KEY_COMPANIES}/${ACTION_NAME_ADD_COMPANY_ITEM}`,
  async ({
    company,
  }, { dispatch }) => {
    const response = await ApiClient.addCompanyItem({ name: company.name });
    if (company.logo && response.data[0].id) {
      dispatch(addCompanyItemLogo({
        companyId: response.data[0].id,
        logo: company.logo,
      }));
    }
    return response;
  },
);

const editCompanyItem = createAsyncThunk<AxiosResponse<any>, { company: CompanyCreationProps }>(
  `${REDUCER_KEY_COMPANIES}/${ACTION_NAME_EDIT_COMPANY_ITEM}`,
  async ({
    company,
  }, { dispatch }) => {
    const response = await ApiClient.editCompanyItem(company);
    if (response.status === 200) {
      dispatch(updateCompanyItem({ company }));
    }
    return response.data;
  },
);

const addCompanyItemLogo = createAsyncThunk<AxiosResponse<any>, { companyId: number, logo: string }>(
  `${REDUCER_KEY_COMPANIES}/${ACTION_NAME_ADD_COMPANY_ITEM_LOGO}`,
  async ({
    companyId,
    logo,
}, { dispatch }) => {
    const { blob: logo_image } = dataToImgFile(logo);
    const logoData = new FormData();
    logoData.append('logo_image', logo_image as Blob, 'logo.jpg');
    const response = await ApiClient.addCompanyItemLogo(companyId, logoData);
    if (response.status === 201) {
      const company = { id: companyId, logo };
      dispatch(updateCompanyItem({ company }));
    }
    return response;
  },
);

const editCompanyItemEnterpriseStatus = createAsyncThunk<AxiosResponse<any>, { company: CompanyEnterpriseEditingProps }>(
  `${REDUCER_KEY_COMPANIES}/${ACTION_NAME_EDIT_COMPANY_ITEM_ENTERPRISE_STATUS}`,
  async ({
    company,
}, { dispatch }) => {
    const response = await ApiClient.editCompanyItemEnterpriseStatus(company);
    return response.data;
  },
);

const changeCompanyTeamUsersStatus = createAsyncThunk<AxiosResponse<any>, { companyId: number }>(
  `${REDUCER_KEY_COMPANIES}/${ACTION_NAME_CHANGE_COMPANY_TEAM_ACTIVE_STATUS}`,
  async ({ companyId }, { dispatch }) => {
    const response = await ApiClient.changeCompanyTeamActiveStatus(companyId);
    return response;
  },
);

const changeCompanyEmail = createAsyncThunk<AxiosResponse<any>, { companyId: number, email: string }>(
  `${REDUCER_KEY_COMPANIES}/${ACTION_NAME_CHANGE_COMPANY_EMAIL}`,
  async ({ companyId, email }) => {
    const response = await ApiClient.changeCompanyEmail({ id: companyId, email });
    return response;
  },
);

const fetchTeams = createAsyncThunk<AxiosResponse<any>, {offset?: number, limit?: number, id: number, filterByName?: string}>(
  `${REDUCER_KEY_COMPANIES}/${ACTION_NAME_FETCH_TEAMS_LIST}`,
  async ({ filterByName, offset = 0, limit = 25, id }) => {
    const response = await ApiClient.fetchTeamsList({offset, limit, id, filterByName});
    return response;
  },
)

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_COMPANIES,
  initialState,
  reducers: {
    resetCompanies: (state) => {
      state.isFetching = false;
      state.companies = [];
      state.selectedCompanyItem = undefined;
    },
    updateCompanyItem: (state, { payload }: PayloadAction<{ company: CompanyCreationProps }>) => {
      state.companies.forEach((companyItem) => {
        if (companyItem.id === payload.company.id) {
          for (let key in payload.company) {
            if (key !== 'id') {
              // @ts-ignore
              companyItem[key] = payload.company[key];
            }
          }
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompaniesList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCompaniesList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchCompaniesList.fulfilled, (state, { payload }) => {
        state.companies = payload.data.companies;
        state.count = payload.data.count;
        state.isFetching = false;
      })
      .addCase(fetchCompanyItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchCompanyItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchCompanyItem.fulfilled, (state, { payload }) => {
        state.selectedCompanyItem = payload.data;
        state.isFetching = false;
      })
      .addCase(addCompanyItem.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addCompanyItem.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addCompanyItem.fulfilled, (state, { payload }) => {
        state.isSaving = false;
        if (state.count < state.rowsPerPage) {
          state.companies = [...state.companies, ...payload.data];
        }
      })
      .addCase(editCompanyItem.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(editCompanyItem.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(editCompanyItem.fulfilled, (state, { payload }) => {
        state.isSaving = false;
      })
      .addCase(addCompanyItemLogo.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addCompanyItemLogo.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addCompanyItemLogo.fulfilled, (state, { payload }) => {
        state.isSaving = false;
      })
      .addCase(editCompanyItemEnterpriseStatus.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(editCompanyItemEnterpriseStatus.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(editCompanyItemEnterpriseStatus.fulfilled, (state, { payload }) => {
        state.isSaving = false;
        state.companies.forEach((companyItem) => {
          // @ts-ignore
          if (companyItem.id === payload[0].id) {
            // @ts-ignore
            companyItem.isEnterpriseAt = payload[0].isEnterpriseAt;
          }
        });
      })

      .addCase(changeCompanyTeamUsersStatus.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(changeCompanyTeamUsersStatus.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(changeCompanyTeamUsersStatus.fulfilled, (state, { payload }) => {
        state.companies = state.companies.map((item) => {
          if (item.id === payload.data.id) {
            return payload.data;
          }
          return item;
        });
        state.isSaving = false;
      })

      .addCase(changeCompanyEmail.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(changeCompanyEmail.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(changeCompanyEmail.fulfilled, (state, { payload }) => {
        state.companies = state.companies.map((item) => {
          if (item.id === payload.data.id) {
            return {
              ...item,
              emailDomain: payload.data.emailDomain,
            }
          }
          return item;
        });
        state.isSaving = false;
      })
      .addCase(fetchTeams.fulfilled, (state, {payload}) => {
        state.teams = payload.data.teams;
      });
  },
});

const {
  resetCompanies,
  updateCompanyItem,
} = actions;

export {
  resetCompanies,
  fetchCompaniesList,
  fetchCompanyItem,
  addCompanyItem,
  editCompanyItem,
  addCompanyItemLogo,
  editCompanyItemEnterpriseStatus,
  changeCompanyTeamUsersStatus,
  changeCompanyEmail,
  fetchTeams,
};

export default reducer;
