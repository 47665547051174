import * as React from "react";
import styled from "styled-components/macro";

import {
  Tooltip,
  Menu,
  MenuItem,
  Divider,
  IconButton as MuiIconButton, Avatar, ListItemText, ListItemIcon,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { logoutUser } from '../../redux/slices/auth';
import { getUserSelector } from '../../redux/selectors/getAuthData';
import { LogoutIcon } from "../../styles/icons/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";


const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(getUserSelector);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    dispatch(logoutUser());
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
          data-test="icon-menu-appbar"
        >
          <Avatar
            alt={`${user.firstName} ${user.lastName}`}
            src={user.avatarUrl}
          />
        </IconButton>
      </Tooltip>
      <Menu
        data-test="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            width: 240,
            mt: 1.5,
          },
        }}
      >
        <MenuItem onClick={() => navigate(ROUTES.ACCOUNT)}>
          <ListItemText primary="Account" />
        </MenuItem>
        <Divider />
        <MenuItem data-test="logout-button" onClick={handleSignOut}>
          <ListItemText primary="Logout" />
          <ListItemIcon sx={{color: '#D4D4D4'}}>
            <LogoutIcon />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
