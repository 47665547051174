import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {AxiosResponse} from 'axios';
import {
  ACTION_NAME_ADD_PURCHASE_ORDER_ITEM,
  ACTION_NAME_FETCH_PURCHASE_ARCHIVE,
  ACTION_NAME_FETCH_PURCHASE_ORDER_FILE_BY_ID,
  ACTION_NAME_GET_PURCHASE_ORDER_FILE,
  ACTION_NAME_PURCHASE_ORDERS_LIST,
  ACTION_NAME_UPDATE_PURCHASE_ORDER_ITEM,
  REDUCER_KEY_PURCHASE_ORDERS,
} from 'redux/constants'
import ApiClient from 'api/ApiClient';
import {
  EPurchaseOrderStatus,
  PurchaseOrder,
  PurchaseOrderCreation,
  PurchaseOrderFilterQueryProps,
  PurchaseOrderUpdate,
} from 'types/purchaseOrders';
import {OrderingType} from 'types/common';

export interface PurchaseOrdersState {
  isFetching: boolean;
  purchaseOrders: PurchaseOrder[];
  count: number;
  page: number;
  isSaving: boolean;
}

const initialState: PurchaseOrdersState = {
  isFetching: false,
  purchaseOrders: [],
  count: 0,
  page: 0,
  isSaving: false,
}

const fetchPurchaseOrdersList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number,
  limit?: number,
  property?: string,
  type?: OrderingType,
  filter?: PurchaseOrderFilterQueryProps,
}>(
  `${REDUCER_KEY_PURCHASE_ORDERS}/${ACTION_NAME_PURCHASE_ORDERS_LIST}`,
  async ({
    offset = 0,
    limit = 25,
    property,
    type= "asc",
    filter,
  }) => {
    const response = await ApiClient.fetchPurchaseOrdersList({ offset, limit, property, type, filter });
    return response;
  },
);

const addPurchaseOrder = createAsyncThunk<AxiosResponse<any>, { purchaseOrder: PurchaseOrderCreation }>(
  `${REDUCER_KEY_PURCHASE_ORDERS}/${ACTION_NAME_ADD_PURCHASE_ORDER_ITEM}`,
  async ({
    purchaseOrder,
  }) => {
    let ids;
    const { file, ...values } = purchaseOrder;
    if (purchaseOrder.file?.length) {
      const promises = purchaseOrder.file.map(async (item) => {
        const formData = new FormData();
        const fileNameWithFormat = item.name;
        formData.append('file', item as Blob, fileNameWithFormat);
        return ApiClient.uploadPurchaseOrderFile(formData, { headers: { 'Content-Type': 'multipart/form-data' }});
      });
      const loadedIds = await Promise.all(promises).then((response) => response.map(({data}) => data.id as number));
      ids = ids ? [...ids, ...loadedIds] : loadedIds;
    }

    const response = await ApiClient.addPurchaseOrderItem({
      ...values,
      privateFileIds: ids,
    });
    return response;
  },
);

const updatePurchaseOrder = createAsyncThunk<AxiosResponse<any>, { purchaseOrder: PurchaseOrderUpdate }>(
  `${REDUCER_KEY_PURCHASE_ORDERS}/${ACTION_NAME_UPDATE_PURCHASE_ORDER_ITEM}`,
  async ({
    purchaseOrder,
  }) => {
    let ids = purchaseOrder.privateFileIds;
    const { file, ...values } = purchaseOrder;

    if (purchaseOrder.file?.length) {
      const promises = purchaseOrder.file.map(async (item) => {
        const formData = new FormData();
        const fileNameWithFormat = item.name;
        formData.append('file', item as Blob, fileNameWithFormat);
        return ApiClient.uploadPurchaseOrderFile(formData, { headers: { 'Content-Type': 'multipart/form-data' }});
      })
      const loadedIds = await Promise.all(promises).then((response) => response.map(({data}) => data.id as number));
      ids = ids ? [...ids, ...loadedIds] : loadedIds;
    }
    const response = await ApiClient.updatePurchaseOrderItem({
      ...values,
      privateFileIds: ids || undefined,
    });
    return response;
  },
);

const downloadPurchaseOrderFile = createAsyncThunk<AxiosResponse<any>, string>(
  `${REDUCER_KEY_PURCHASE_ORDERS}/${ACTION_NAME_GET_PURCHASE_ORDER_FILE}`,
  async (link) => {
    const response = await ApiClient.downloadPurchaseOrderFile(link);
    return response;
  },
);

const fetchPurchaseOrdersFileById = createAsyncThunk<AxiosResponse<any>, number>(
  `${REDUCER_KEY_PURCHASE_ORDERS}/${ACTION_NAME_FETCH_PURCHASE_ORDER_FILE_BY_ID}`,
  async (id) => {
    return await ApiClient.getPurchaseOrderFileById(id);
  },
);

const fetchPurchaseOrdersArchiveFile = createAsyncThunk<AxiosResponse<any>, number>(
  `${REDUCER_KEY_PURCHASE_ORDERS}/${ACTION_NAME_FETCH_PURCHASE_ARCHIVE}`,
  async (id) => {
    return await ApiClient.getPurchaseOrderArchieve(id);
  },
)

const {actions, reducer} = createSlice({
  name: REDUCER_KEY_PURCHASE_ORDERS,
  initialState,
  reducers: {
    resetPurchaseOrders: (state) => {
      state.isFetching = false;
      state.purchaseOrders = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseOrdersList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchPurchaseOrdersList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchPurchaseOrdersList.fulfilled, (state, { payload }) => {
        state.purchaseOrders = payload.data.purchaseOrders;
        state.count = payload.data.count;
        state.page = payload.data.page;
        state.isFetching = false;
      })
      .addCase(addPurchaseOrder.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addPurchaseOrder.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addPurchaseOrder.fulfilled, (state, { payload }) => {
        state.purchaseOrders.push(payload.data);
        state.isSaving = false;
      })
      .addCase(updatePurchaseOrder.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(updatePurchaseOrder.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(updatePurchaseOrder.fulfilled, (state, { payload }) => {
        state.purchaseOrders = state.purchaseOrders.map((item) => {
          if (item.id === payload.data.id) {
            return payload.data;
          }
          return item;
        });
        state.isSaving = false;
      });
  },
});

const {
  resetPurchaseOrders,
} = actions;

export {
  resetPurchaseOrders,
  fetchPurchaseOrdersList,
  addPurchaseOrder,
  updatePurchaseOrder,
  downloadPurchaseOrderFile,
  fetchPurchaseOrdersFileById,
  fetchPurchaseOrdersArchiveFile,
};

export default reducer;
