import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  REDUCER_KEY_PRODUCTS,
  ACTION_NAME_EDIT_PRODUCT_ITEM,
  ACTION_NAME_FETCH_PRODUCTS_LIST,
  ACTION_NAME_FETCH_PRODUCT_ITEM,
  UPDATE_WORK_TYPES,
  CREATE_WORK_TYPES,
  UPDATE_PRODUCT_MODULES,
  CREATE_PRODUCT_MODULES,
  ACTION_NAME_CREATE_PRODUCT_ITEM,
  ACTION_NAME_FETCH_PRODUCTS_SUMMARY_DATA,
  ACTION_NAME_FETCH_PRODUCT_AVAILABILITY,
} from '../constants'
import ApiClient from 'api/ApiClient';
import {
  AddProductModulesProps,
  AddWorkTypeProps,
  IProductLarge,
  ProductAvailability,
  ProductCreatingProps,
  ProductEditingProps,
  ProductsFilterQueryProps,
  UpdateWorkTypeProps
} from 'types/products';
import { UpdateProductModulesProps, ProductsSummaryDataProps } from 'types/products';

export interface ProductsState {
  isFetching: boolean;
  products: IProductLarge[];
  count: number;
  page: number;
  isSaving: boolean;
  selectedProductItem?: IProductLarge;
  summaryData?: ProductsSummaryDataProps;
  specialistAvailability: ProductAvailability[];
}

const initialState: ProductsState = {
  isFetching: false,
  products: [],
  count: 0,
  page: 0,
  isSaving: false,
  selectedProductItem: undefined,
  summaryData: undefined,
  specialistAvailability: [],
}

const fetchProductsList = createAsyncThunk<AxiosResponse<any>, {
  offset?: number,
  limit?: number,
  search?: string,
  orderByField?: string,
  orderBySequence?: "desc" | "asc",
  filter?: ProductsFilterQueryProps,
}>(
  `${REDUCER_KEY_PRODUCTS}/${ACTION_NAME_FETCH_PRODUCTS_LIST}`,
  async ({
  offset = 0,
  limit = 25,
  search,
  orderByField,
  orderBySequence = "asc",
  filter,
  }) => {
    const response = await ApiClient.fetchProductsList({ offset, limit, search, orderByField, orderBySequence, filter});
    return response;
  },
);

const fetchProductAvailability = createAsyncThunk<AxiosResponse<any>> (
  `${REDUCER_KEY_PRODUCTS}/${ACTION_NAME_FETCH_PRODUCT_AVAILABILITY}`,
  async() => {
    const response = await ApiClient.fetchProductAvailability();
    return response
  }
);


const fetchProductItem = createAsyncThunk<AxiosResponse<any>, { id: number }>(
  `${REDUCER_KEY_PRODUCTS}/${ACTION_NAME_FETCH_PRODUCT_ITEM}`,
  async ({
    id,
}) => {
    const response = await ApiClient.fetchProductItem(id);
    return response;
  },
);

const editProductItem = createAsyncThunk<AxiosResponse<any>, { product: ProductEditingProps }>(
  `${REDUCER_KEY_PRODUCTS}/${ACTION_NAME_EDIT_PRODUCT_ITEM}`,
  async ({
  product,
}) => {
    const response = await ApiClient.editProductItem(product);
    return response;
  },
);

const addProductItem = createAsyncThunk<AxiosResponse<any>, { product: ProductCreatingProps }>(
  `${REDUCER_KEY_PRODUCTS}/${ACTION_NAME_CREATE_PRODUCT_ITEM}`,
  async ({ product }) => {
    const response = await ApiClient.createProductItem(product);
    return response;
  },
);

const updateWorkType = createAsyncThunk<AxiosResponse<any>, { workType: UpdateWorkTypeProps }>(
  `${REDUCER_KEY_PRODUCTS}/${UPDATE_WORK_TYPES}`,
  async ({
  workType,
}, { dispatch }) => {
    const response = await ApiClient.updateWorkTypes(workType);
    if (response.status === 200) {
      dispatch(updateWorkTypeState({ workType }));
    }
    return response;
  },
);

const addWorkType = createAsyncThunk<AxiosResponse<any>, { workType: AddWorkTypeProps }>(
  `${REDUCER_KEY_PRODUCTS}/${CREATE_WORK_TYPES}`,
  async ({ workType }) => {
    const response = await ApiClient.createWorkTypeItem(workType);
    return response;
  },
);

const updateProductModule = createAsyncThunk<AxiosResponse<any>, { productModule: UpdateProductModulesProps }>(
  `${REDUCER_KEY_PRODUCTS}/${UPDATE_PRODUCT_MODULES}`,
  async ({
    productModule,
}, { dispatch }) => {
    const response = await ApiClient.updateProductModules(productModule);
    if (response.status === 200) {
      dispatch(updateProductModuleState({ productModule }));
    }
    return response;
  },
);
const addProductModule = createAsyncThunk<AxiosResponse<any>, { productModule: AddProductModulesProps }>(
  `${REDUCER_KEY_PRODUCTS}/${CREATE_PRODUCT_MODULES}`,
  async ({ productModule }) => {
    const response = await ApiClient.createProductModulesItem(productModule);
    return response;
  },
);

const fetchProductsSummaryData = createAsyncThunk<AxiosResponse<any>,{
  offset?: number,
  limit?: number,
}>(
  `${REDUCER_KEY_PRODUCTS}/${ACTION_NAME_FETCH_PRODUCTS_SUMMARY_DATA}`,
  async ({
    offset = 0,
    limit = 10,
  }) => {
    const response = await ApiClient.fetchProductsSummaryData({ offset, limit });
    return response;
  },
);

const { actions, reducer } = createSlice({
  name: REDUCER_KEY_PRODUCTS,
  initialState,
  reducers: {
    resetProducts: (state) => {
      state.isFetching = false;
      state.products = [];
      state.selectedProductItem = undefined;
    },
    updateProductItem: (state, { payload }: PayloadAction<{ product: ProductEditingProps }>) => {
      state.products.forEach((productItem) => {
        if (productItem.id === payload.product.id) {
          productItem.isActive = !!payload.product.isActive;
        }
      });

      if (state.selectedProductItem && state.selectedProductItem.id === payload.product.id) {
        state.selectedProductItem.isActive = !!payload.product.isActive;
      }
    },
    updateWorkTypeState: (state, { payload }: PayloadAction<{ workType: UpdateWorkTypeProps }>) => {
      if (state.selectedProductItem && state.selectedProductItem.workTypes) {
        state.selectedProductItem.workTypes.forEach((data) => {
          if (data.id === payload.workType.id) {
            data.isActive = !!payload.workType.isActive;
            data.isDeprecated = !!payload.workType.isDeprecated;
          }
        })
      }
    },
    updateProductModuleState: (state, { payload }: PayloadAction<{ productModule: UpdateProductModulesProps }>) => {
      if (state.selectedProductItem && state.selectedProductItem.productModules) {
        state.selectedProductItem.productModules.forEach((data) => {
          if (data.id === payload.productModule.id) {
            data.isActive = !!payload.productModule.isActive;
            data.isDeprecated = !!payload.productModule.isDeprecated;
          }
        })
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsList.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchProductsList.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchProductsList.fulfilled, (state, { payload }) => {
        state.products = payload.data.products;
        state.count = payload.data.count;
        state.page = payload.data.page;
        state.isFetching = false;
      })
      .addCase(fetchProductAvailability.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchProductAvailability.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchProductAvailability.fulfilled, (state, { payload }) => {
        state.specialistAvailability = payload.data;
        state.isFetching = false;
      })
      .addCase(fetchProductItem.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchProductItem.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchProductItem.fulfilled, (state, { payload }) => {
        state.selectedProductItem = payload.data;
        state.isFetching = false;
      })
      .addCase(editProductItem.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(editProductItem.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(editProductItem.fulfilled, (state, { payload }) => {
        if (state.selectedProductItem) {
          state.selectedProductItem = {
            ...state.selectedProductItem,
            ...payload.data[0],
          };
        }
        state.products = state.products.map((productItem) => {
          if (productItem.id === payload.data[0]?.id) {
            return payload.data[0];
          }
          return productItem;
        });
        state.isSaving = false;
      })
      .addCase(addProductItem.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addProductItem.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addProductItem.fulfilled, (state, { payload }) => {
        state.isSaving = false;
      })
      .addCase(updateWorkType.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(updateWorkType.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(updateWorkType.fulfilled, (state, { payload }) => {
        state.isSaving = false;
      })
      .addCase(addWorkType.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addWorkType.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addWorkType.fulfilled, (state, { payload }) => {
        if (state.selectedProductItem) {
          state.selectedProductItem = {
            ...state.selectedProductItem,
            workTypes: [
              ...state.selectedProductItem?.workTypes,
              ...payload.data,
            ],
          };
        }
        state.isSaving = false;
      })
      .addCase(updateProductModule.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(updateProductModule.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(updateProductModule.fulfilled, (state, { payload }) => {
        state.isSaving = false;
      })

      .addCase(addProductModule.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(addProductModule.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(addProductModule.fulfilled, (state, { payload }) => {
        if (state.selectedProductItem) {
          state.selectedProductItem = {
            ...state.selectedProductItem,
            productModules: [
              ...state.selectedProductItem?.productModules,
              ...payload.data,
            ],
          };
        }
        state.isSaving = false;
      })

      .addCase(fetchProductsSummaryData.pending, (state) => {
        state.isFetching = true;
        state.summaryData = undefined;
      })
      .addCase(fetchProductsSummaryData.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(fetchProductsSummaryData.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.summaryData = payload.data;
      });
  },
});

const {
  resetProducts,
  updateProductItem,
  updateWorkTypeState,
  updateProductModuleState,
} = actions;

export {
  resetProducts,
  fetchProductsList,
  fetchProductAvailability,
  fetchProductItem,
  editProductItem,
  addProductItem,
  updateWorkType,
  addWorkType,
  updateProductModule,
  addProductModule,
  fetchProductsSummaryData,
};

export default reducer;
