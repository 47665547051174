import React from 'react';

import async from './components/Async';

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import DashboardLayout from './layouts/Dashboard';
import AuthLayout from './layouts/Auth';

import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import { ROUTES } from './constants';
import { ROLE } from './models/const';
import VerificationCode from 'pages/ResetPassword/VerificationCode';
import RecoverPassword from 'pages/ResetPassword/RecoverPassword';

// Pages
const Orders = async(() => import("./pages/Orders"));
const OrderItem = async(() => import("./pages/OrderItem"));
const OrderCreation = async(() => import("./pages/OrderCreation"));
const Consultants = async(() => import("./pages/Consultants"));
const Influencers = async(() => import("./pages/Influencers"));
const Customers = async(() => import("./pages/Customers"));
const ConsultantItem = async(() => import("./pages/ConsultantItem"));
const Reviews = async(() => import("./pages/Reviews"));
const ReviewItem = async(() => import("./pages/ReviewItem"));
const Tags = async(() => import("./pages/Tags"));
const Companies = async(() => import("./pages/Companies"));
const Agencies = async(() => import("./pages/Agencies"));
const Feedbacks = async(() => import("./pages/Feedbacks"));
const FeedbackItem = async(() => import("./pages/FeedbackItem"));
const Products = async(() => import("./pages/Products"));
const ProductItem = async(() => import("./pages/ProductItem"));
const PurchaseOrders = async(() => import("./pages/PurchaseOrders"));
const WeeklyBillings = async(() => import("./pages/WeeklyBillings"));
const EnterpriseInvoicing = async(() => import("./pages/EnterpriseInvoicing"));
const AgencyPayouts = async(() => import("./pages/AgencyPayouts"));
const Leads = async(() => import("./pages/Leads"));
const LeadItem = async(() => import("./pages/LeadItem"));
const ServiceCatalogEntries = async(() => import("./pages/ServiceCatalogs"));
const ServiceCatalogEntry = async(() => import("./pages/ServiceCatalogItem"));
const LeadManagers = async(() => import("./pages/LeadManagers"));
const LeadManagerItem = async(() => import("./pages/LeadManagerItem"));
const Account = async(() => import("./pages/Account"));
const BusinessRules = async(() => import("./pages/BusinessRules"));
const Users = async(() => import("./pages/Users"));

// Dashboard components
const Home = async(() => import("./pages/Home"));

const routes = [
  {
    path: ROUTES.HOME,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.LEAD_MANAGER, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <Home />,
      },
    ],
  },
  {
    path: ROUTES.ORDERS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <Orders />,
      },
      {
        path: ROUTES.ORDER_ITEM,
        element: <OrderItem />,
      },
      {
        path: ROUTES.ORDER_CREATE_EDIT,
        element: <OrderCreation />
      },
      {
        path: ROUTES.ORDER_MANAGE,
        element: <OrderCreation />
      }
    ],
  },
  {
    path: ROUTES.CONSULTANTS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <Consultants />,
      },
      {
        path: ROUTES.CONSULTANT_ITEM,
        element: <ConsultantItem />,
      },
    ],
  },
  {
    path: ROUTES.INFLUENCERS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <Influencers />,
      },
    ],
  },
  {
    path: ROUTES.REVIEWS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <Reviews />,
      },
      {
        path: ROUTES.REVIEW_ITEM,
        element: <ReviewItem />,
      },
    ],
  },
  {
    path: ROUTES.CUSTOMERS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <Customers />,
      },
    ],
  },
  {
    path: ROUTES.TAGS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <Tags />,
      },
    ],
  },
  {
    path: ROUTES.COMPANIES,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <Companies />,
      },
    ],
  },
  {
    path: ROUTES.AGENCIES,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <Agencies />,
      },
    ],
  },
  {
    path: ROUTES.FEEDBACKS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <Feedbacks />,
      },
      {
        path: ROUTES.FEEDBACK_ITEM,
        element: <FeedbackItem />,
      },
    ],
  },
  {
    path: ROUTES.PRODUCTS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <Products />,
      },
      {
        path: ROUTES.PRODUCT_ITEM,
        element: <ProductItem />,
      },
    ],
  },
  {
    path: ROUTES.PURCHASE_ORDERS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES]} />,
    children: [
      {
        path: "",
        element: <PurchaseOrders />,
      },
    ],
  },
  {
    path: ROUTES.WEEKLY_BILLINGS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <WeeklyBillings />,
      },
    ],
  },
  {
    path: ROUTES.ENTERPRISE_INVOICING,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS]} />,
    children: [
      {
        path: "",
        element: <EnterpriseInvoicing />,
      },
    ],
  },
  {
    path: ROUTES.AGENCY_PAYOUTS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS]} />,
    children: [
      {
        path: "",
        element: <AgencyPayouts />,
      },
    ],
  },
  {
    path: ROUTES.LEADS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.LEAD_MANAGER]} />,
    children: [
      {
        path: "",
        element: <Leads />,
      },
      {
        path: ROUTES.LEAD_ITEM,
        element: <LeadItem />,
      },
    ],
  },
  {
    path: ROUTES.LEAD_MANAGERS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.LEAD_MANAGER]} />,
    children: [
      {
        path: "",
        element: <LeadManagers />,
      },
      {
        path: ROUTES.LEAD_MANAGER_ITEM,
        element: <LeadManagerItem />,
      },
    ],
  },
  {
    path: ROUTES.BUSINESS_RULES,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <BusinessRules />,
      },
    ],
  },
  {
    path: ROUTES.SERVICE_CATALOG_ENTRIES,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.LEAD_MANAGER]} />,
    children: [
      {
        path: "",
        element: <ServiceCatalogEntries />,
      },
      {
        path: ROUTES.SERVICE_CATALOG_ENTRY,
        element: <ServiceCatalogEntry />,
      },
    ],
  },
  {
    path: ROUTES.USERS,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN]} />,
    children: [
      {
        path: "",
        element: <Users />
      }
    ]
  },
  {
    path: ROUTES.AUTH,
    element: <AuthLayout />,
    children: [
      {
        path: ROUTES.SIGN_IN,
        element: <SignIn />,
      },
      {
        path: `${ROUTES.SIGN_UP}/code/:hash`,
        element: <SignUp />,
      },
      {
        path: ROUTES.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: ROUTES.CODE,
        element: <VerificationCode />
      },
      {
        path: ROUTES.RECOVER_PASSWORD,
        element: <RecoverPassword />,
      },
      // {
      //   path: "404",
      //   element: <Page404 />,
      // },
      // {
      //   path: "500",
      //   element: <Page500 />,
      // },
    ],
  },
  {
    path: ROUTES.ACCOUNT,
    element: <DashboardLayout allowedRoles={[ROLE.ADMIN, ROLE.LEAD_MANAGER, ROLE.OPERATIONS, ROLE.SALES, ROLE.TALENT]} />,
    children: [
      {
        path: "",
        element: <Account />,
      },
    ]
  }
];

export default routes;
